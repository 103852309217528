.screen-overlay-header-row {
  padding: 16px 24px;
  display: flex;
  font-family: 'Rubik', 'Roboto', sans-serif;
  font-size: 24px;
  align-items: center;
  top: 0px;
  position: sticky;
  z-index: 100;
  background: #fff;
  margin-left: -16px;
  width: calc(100% + 32px);
  box-shadow: 0px 4px 4px -2px rgba(0,0,0, 0.3);
}
.screen-overlay-content {
  padding-top: 24px;
}
.naadi-screen-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  .naadi-screen-loader-logo {
    width: 100%;
    text-align: center;
    margin-top: -80px;
  }
  &.confirm-box {
    z-index: 1000;
  }
  @include media-breakpoint-up(lg) {
    padding: 24px;
  }
}
.naadi-modal-default-bound {
  margin: auto;
  background: #fff;
  min-width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 16px;
  bottom: 0px;
  padding-bottom: 32px;
  position: absolute;
  // Medium devices (tablets, less than 992px)
  @include media-breakpoint-up(lg) {
    //min-width: 100%;
    min-width: unset;
    position: absolute;
    bottom: unset;
    min-height: 30vh;
    border-radius: 16px;
    padding-bottom: 16px;
    padding-top: 16px;
    top: 32px;
    left: 32px;
    right: 32px;
    //bottom: 32px;
  }
  // Medium devices (tablets, less than 992px)
  @include media-breakpoint-up(lg) {
    padding: 24px;
    border-radius: 16px;
    margin: auto;
  }
}

.naadi-modal-confirm-status-icon {
  padding-top: 48px;
  .confirm-icons-message {
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    align-items: flex-end;
    div {
      //padding-left: 24px;
      padding-right: 24px;
    }
    .large-icon {
      display: none;
    }
  }
  // Medium devices (tablets, less than 992px)
  @include media-breakpoint-up(lg) {
    .confirm-icons-message {
      .small-icon {
        display: none;
      }
      .large-icon {
        display: block;
        margin-top: 0px;
      }
    }
  }
  // Medium devices (tablets, less than 992px)
  @include media-breakpoint-up(lg) {
    .confirm-icons-message {
      display: block;
      .small-icon {
        display: none;
      }
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 48px;
      .large-icon {
        padding-top: 24px;
        transform: scale(1.5);
      }
      div {
        margin-top: 24px;
        margin-bottom: -24px;
      }
    }
    text-align: center;
    padding-top: 24px;
  }
}

.naadi-modal-confirm-header {
  font-weight: 500;
  font-size: 24px;
  padding: 24px;
  padding-left: 48px;
  padding-right: 48px;
  text-align: left;
  width: 100%;
  line-height: 26px;
  // Medium devices (tablets, less than 992px)
  @include media-breakpoint-up(lg) {
    text-align: center;
  }
}
.naadi-modal-confirm-button {
  width: 100%;
  padding: 16px 5px;
  padding-bottom: 8px;
  min-height: 48px;
  color: #0b1623;

  .naadi-modal-confirm-button-content {
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #0b1623;
    text-align: center;
    cursor: pointer;
    &.btn-primary {
      color: #fff;
      background: #0b1623;
    }
  }
}
.naadi-modal-overlay {
  min-height: 300px;
  background: #ffffff;
}
.naadi-modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  overflow: visible;
  cursor: pointer;
  z-index: 1000;
}
.naadi-modal-content {
  margin-top: 16px;
}
.naadi-confirm-message-text {
  @include media-breakpoint-up(lg) {
    padding-top: 16px;
  }
}
