$button-border-radius-lg: 8px;
$button-border-radius-md: 6px;
$button-border-radius-sm: 4px;
$button-label-size: 16px;

$primary-color: #027aff;


.btn {
  border-radius: $button-border-radius-md;
  font-size: $button-label-size;
  line-height: 19px;
  text-align: center;
  padding: 14px 16px;
  min-width: 120px;
  &.small {
    min-width: 32px;
  }
}

.btn-primary {
  background: $primary-color;
  border-color: $primary-color;
}
.btn-outline-primary{
  color: $primary-color;
  &:hover{
    color: $primary-color;
    background-color: transparent;
  }
}
.btn-primary-tapped{
  color: $primary-color !important;
  background-color: $primary-color;
  background-color: rgba($primary-color,0.1);
  &:hover{
    color: $primary-color;
    background-color: rgba($primary-color,0.1) !important;
  }
  &:active{
    color: $primary-color;
    background-color: rgba($primary-color,0.1) !important;
  }
  &:focus{
    color: $primary-color !important;
    background-color: rgba($primary-color,0.1) !important;
  }

}
.text-button {
  font-family: Rubik;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #007bff;
  cursor: pointer;
  &.disabled {
    color: rgba(179, 179, 179, 0.8);
    cursor: unset;
  }
}
.text-button-padded {
  padding: 4px 16px;
}

.order-list-item {
  border-bottom: 1px solid #dedede;
  padding: 12px;
  margin-left: 40px;
  padding-left: 0px;

  &:last-child {
    margin-bottom: 22px;
  }
}

.child-order-accordion {
  @extend .position-relative, .overflow-hidden;
  &::before {
    content: ' ';
    width: 1px;
    height: 60%;
    top: 35px;
    left: 6px;
    background-color: #eaeaea;
    @extend .position-absolute;
  }
}

.child-order-list-item {
  margin-left: 40px;
  @extend .py-2;
}

.child-orders-list {
  list-style: 'none';
}

.child-order-list-item-no-children {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #027aff;
}

.order-left-list-item {
  padding-bottom: 22px;
  padding-left: 12px;
}

.order-left-list-item-header {
  padding-bottom: 22px;
  padding-left: 12px;
}

.order-create-order-info-item-wrapper {
  padding-bottom: 22px;
  padding-left: 12px;

  .order-create-order-info-item-header {
    padding-bottom: 22px;
    padding-left: 12px;
  }
}

.floating-btn {
  @extend .btn;
  background: #027aff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 40px;
  right: 40px;
  border-radius: 50%;
  height: 47px;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 47px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.text-button {
}

.btn-with-icon {
  position: relative;
  .btn-text{
    padding-right: 16px;
  }
  .btn-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.react-switch-bg{
  border : 1px solid rgba(#027AFF,0.4) !important;
}
.toggled{
  react-switch-bg{
    border: 0px;
  }
}
