.framework-modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.framework-modal {
  position: fixed;
  width: 400px;
  z-index: 12;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @extend .border, .shadow, .bg-white;

  &.full {
    height: 100vh;
    width: 100% !important;
  }

  .framework-modal-container {
    .framework-modal-heading {
      @extend h4, .p-3, .border-bottom;
    }

    .framework-modal-body {
      @extend .p-3;
    }
  }
}

@media (max-width: 1025px) {
  .framework-modal {
    position: fixed !important;
    width: 400px !important;
    z-index: 12 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    overflow-y: auto;
  }
}

@media (min-width: 1025px) {
  .framework-modal.full {
    position: absolute !important;
    /* width: 400px; */
    z-index: 12 !important;
    top: 0 !important;
    width: calc(100% - 270px) !important;
    margin-left: 270px !important;
    height: 100vh;
    overflow-y: auto;
    transform: none !important;
    left: 0 !important;
    border: none !important;
  }
}

.modal-content {
  background: #ffffff;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 12px;
}

.modal-backdrop {
  background: #323232;

  &.show {
    opacity: 0.6;
  }
}

.modal-title {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.342857px;
  color: #323232;
}

.pink-circle {
  background: rgba(242, 132, 130, 0.2);
  mix-blend-mode: normal;
}

.yellow-circle {
  background: rgba(255, 179, 0, 0.2);
  mix-blend-mode: normal;
}

.blue-circle {
  background: rgba(34, 182, 207, 0.2);
  mix-blend-mode: normal;
}
.purple-circle {
  background: rgba(34, 50, 207, 0.2);
  mix-blend-mode: normal;
}


.modal-circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  @extend .mx-auto;
}

.modal-body {
  padding-top: 48px;
  padding-bottom: 82px;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.naadi-react-modal{
  vertical-align: center;
}
.naadi-modal-mobile{
  @include media-breakpoint-down(md){
    bottom: 0px;
    height: 100% !important;
    margin-bottom: 0px !important;
    max-width: 100% !important;
    margin: 0px !important;
    .modal-content{
      position: absolute;
      bottom: 0px;
      width: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .modal-body{
      padding-bottom: 32px;
    }
  }
  .modal-header{
    padding: 16px 32px;
  }
  .modal-body{
    padding: 16px 32px;
  }

}