.cursor-pointer {
  cursor: pointer;
}
.text-no-border {
  color: #007bff !important;
  border-width: 0px;
  outline-color: transparent;
  appearance: none;
}

.display-flex{
  display: flex;
}
.display-none{
  display: none;
}

.flex-1{
  flex:1
}

.flex-2{
  flex:2
}

.flex-3{
  flex:3
}
.flex-4{
  flex:4
}
.flex-5{
  flex:5
}