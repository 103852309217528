@mixin transition($time, $type) {
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

.scroll-content {
  height: 100vh;
  overflow: auto;
  padding-bottom: 24px;
}

.container-fluid {
  // background-color: #ffffff;
}
