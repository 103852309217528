.text-right{
  text-align: right;
}
.py-16{
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-32{
  padding-top: 32px;
  padding-bottom: 32px;
}
.px-32{
  padding-left: 32px;
  padding-right: 32px;
}

.pt-64{
 padding-top: 64px;
}
a{
  text-decoration: none;
  cursor: pointer;
}
.py-48{
  padding-top: 48px;
  padding-bottom: 48px;
}
.py-64{
  padding-top: 64px;
  padding-bottom: 64px;
}
.px-64{
  padding-left: 64px;
  padding-right: 64px;
}
.header-logo-row-wrp{
  padding: 32px;
  z-index: 10;
  max-width: 100%;
  background: #FFFFFF;
  position: sticky;
  top: 0px;
  margin: 0px;
}
.header-logo-wrp{
  width: 240px;
}
.header-login-wrp{
  justify-content: end;
  width: 100px;
  color: #027AFF;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  padding: 0px;
  @media screen and (max-width: 400px)  {
    display: none;
  }

}
.header-links-wrp{
  //max-width: 480px;
  height: 100%;
  justify-content: end;
  .link-level-1{
    height: 100%;
    padding: 0px;
    color: #323232;
    font-size: 24px;
    min-width: 110px;
    @include media-breakpoint-down(lg) {
      min-width: 90px;
    }

    div{
      padding: 8px 32px 8px 0px;
      @include media-breakpoint-down(lg) {
        padding: 8px 16px 8px 0px;
        font-size: 20px;
      }
    }
    .dropdown-toggle::after{
      display: none;
    }
    a{
      color: #323232;
      padding: 8px 32px 8px 0px;
      font-size: 24px;
      @include media-breakpoint-down(lg) {
        padding: 8px 16px 8px 0px;
        font-size: 20px;
      }
      &:hover{
        background-color: initial;
      }
    }
    .header-nav-item{
      font-family: "Rubik Roboto";
      font-size: 24px;
      line-height: 28px;
      padding-left: 16px;
      color: #0B1623;
      &::selection{
        background-color: initial;
      }

    }
  }
  .selected{
    border-bottom: 1px solid #000;
  }
}
.section-1-wrp{
  color: #0B1623;
  padding: 32px;
  padding-top: 90px;
  @include media-breakpoint-down(lg) {
    padding-top: 16px;
  }
  .top-suffix{
    font-size: 16px;
    position: relative;
    top: -14px;
  }
  .section-1-header-md{
    font-family: 'Rubik', 'Roboto';
    font-size: 37px;
    line-height: 44px;
    @include media-breakpoint-down(xl) {
      font-size: 24px;
    }
  }
  .section-1-header-lg{
    font-family: 'Rubik', 'Roboto';
    font-size: 86px;
    line-height: 102px;
    @include media-breakpoint-down(xl) {
      font-size: 72px;
      line-height: 90px;
    }
    @media screen and (max-width: 1092px) {
      font-size: 60px;
      line-height: 82px;
    }
    @media screen and (max-width: 500px) {
      font-size: 40px;
      line-height: 48px;
    }

  }
  .section-1-content{
    font-size: 28px;
    line-height: 33px;
    justify-content: end;
    max-width: 460px;
    margin-left: auto;
    @media screen and (max-width: 992px)  {
      padding-top: 32px;
      margin: auto;
      margin-left: 0px;
    }
    @media screen and (max-width: 500px) {
      font-size: 24px;
      line-height: 28px;
    }

  }
  .section-1-video-header-wrp{
    position: relative;
    padding-top: 72px;
    font-size: 23px;
    font-family: 'Rubik', 'Roboto';
    @media screen and (max-width: 500px) {
      font-size: 18px;
      line-height: 22px;
    }
    .separator{
      display: flex;
      position: relative;
      min-width: 210px;
      &::after{
        position: absolute;
        right: 12px;
        top : 0px;
        content: '.';
        height: 0px;
        width: 0px;
      }
    }
  }
  .section-1-video-wrp{
    padding: 40px;
    max-width: 1054px;
    height: 700px;
    margin: 0 auto;
  }
}
.section-2-wrp{
  padding: 48px 32px;
  .section-2-header{
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 52px;
    line-height: 100%;
    text-align: center;
  }
  .section-2-content{
    padding-top: 64px;
    .module-image-wrp{
      height: 400px;
      @media screen and (max-width: 992px) {
        position: relative;
      }
      @media screen and (max-width: 772px) {
        height: initial;
        padding: 32px 0px;
      }
    }
    .module-content-padded{
      padding-left: 32px;

    }
    .title{
      font-size: 36px;
      font-family: 'Rubik', 'Roboto';
    }

    &.section-2-sub-content{
      @media screen and (max-width: 772px) {
        padding-top: 0px;
      }
      .content{
        font-size: 24px !important;
        line-height: 28px;
      }
    }
    .content{
      font-size: 28px;
      line-height: 33px;
      line-height: 100%;
    }
  }

}
.section-3-wrp{
  padding: 32px;
  line-height: 190%;

}
.gradient-line{
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right,rgba(0, 0, 0, 0),rgba(0,0,0,1),rgba(0, 0, 0, 0));
}
.home-page-content-header{
  font-family: "Rubik Roboto";
  font-size: 52px;
  line-height: 62px;
  text-align: center;
}
.our-partners-wrp{
  padding: 32px;
  .our-partners-header{

  }
  .our-partners-what-they-say{
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    padding: 32px;
  }
  .testimonial-wrp{
    width: 100%;
    height: 506px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
  }
  .testimonial-img{
    width: 100%;
    height: 340px;
  }
  .testimonial-content{
    font-family: "Rubik Roboto";
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}
.onboarding-jrn-wrp{
  padding: 32px;
  .onboarding-jrn-txt{
    padding: 64px;
    font-size: 28px;
    line-height: 54px;
    text-align: center;
  }
  .onboarding-jrn-content-header{
    font-family: "Rubik Roboto";
    font-size: 36px;
    line-height: 43px;
    padding-bottom: 32px;
  }
  .onboarding-jrn-content-sep{
    border-right: 1px dashed #0B1623;
    color: #000000;
    @media screen and (max-width: 992px) {
      border-right: 0px;
    }
  }
  .onboarding-jrn-content-text{
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .onboarding-jrn-content-wrp{
    @media screen and (max-width: 992px) {
      padding-top: 96px;
    }
  }
  .onboarding-signoff-wrp{
    margin: auto;
    max-width: 1024px;
    color: #000000;
    font-size: 52px;
    line-height: 61px;
  }
}
.naadi-website-footer-wrp{
  width: 100%;
  background-color: #0B1623;
  color: #FFFFFF;
  .footer-links{
    font-family: "Rubik Roboto";
    padding: 32px;
    font-size: 24px;
    margin: auto;
    max-width: 882px;
    cursor: pointer;
  }
  .footer-text{
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 64px;
  }
}
.input-box-border{
  border: 1px solid rgba(#DEDEDE,0.8);
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 16px;
  input{
    border: 0px;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DEDEDE;
    opacity: 0.8; /* Firefox */
  }
}
.input-label{
  font-size: 10px;
  color: rgba(#323232,0.8);
}
.image-max-width{
  max-width: 100%;
  object-fit: contain;
}
.video-max-height{
  @media screen and (max-width: 500px) {
    max-height: 350px;
  }
  @media screen and (max-width: 400px) {
    max-height: 250px;
  }
  @media screen and (max-width: 300px) {
    max-height: 150px;
  }
}